/* eslint-disable */
import API from "@doar/shared/methods/api";
import { ChannelAssignment } from "src/models/ChannelAssigment";
import { User } from "src/models/User";
import { v4 as uuidv4 } from "uuid";

//ASSIGNMENTS
export const createAssignment = async (body: ChannelAssignment) => {
  const response = await API.post("/assignments", body);
  return response.data;
};

export const deleteAssigment = async (assigmentId: string) => {
  const response = await API.delete(`/assignments/${assigmentId}`);
  return response.data;
};

export const getAssigmentByNumber = async (number: string, customerId: string) => {
  const cleanedNumber = number[0] === "+" ? number.slice(1) : number;

  const response = await API.get(`/assignments?channel_id=${cleanedNumber}&owner_id=${customerId}`);
  return response.data.data;
};

export const updateAssignment = async (assignment: ChannelAssignment): Promise<void> => {
  try {
    const response = await API.put(`/assignments/${assignment.id}`, assignment);

    console.log("Assignment actualizado con éxito");
    return response.data.data || [];
  } catch (error) {
    console.error("Error en updateAssignment:", error);
    throw error;
  }
};

export const getAssignmentsIds = async () => {
  const response = await API.get("assignments/ids?&channel_type=PHONE");
  return response.data.data;
};

// INTEGRATIONS
export const getIntegrations = async (customerId: string): Promise<any[]> => {
  const url = customerId === "bookline" ? "" : "?integration_scope=CUSTOMER";
  try {
    const response = await API.get(`/customers/${customerId}/integrations${url}`);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching integrations:", error);
    throw error;
  }
};
export const getIntegrationById = async (customerId: any, id: string): Promise<any[]> => {
  try {
    const response = await API.get(`/customers/${customerId}/integrations/${id}`);
    return response.data || [];
  } catch (error) {
    console.error("Error fetching integrations:", error);
    throw error;
  }
};

// BOTS

async function getBots(offset: number, customerId: string, selects?: string[], botType?: string): Promise<any> {
  const queryParams = new URLSearchParams();
  queryParams.append("owner_id", customerId);

  if (selects && selects.length > 0) {
    selects.forEach((field) => queryParams.append("select", field));
  }

  if (botType) {
    queryParams.append("bot_type", botType);
  }
  const response: any = await API.get(`/bots?${queryParams.toString()}&offset=${offset}`);

  return response.data;
}

export const getAllBotsByCustomer = async (customerId: string, selects?: string[], botType?: string): Promise<any[]> => {
  let response_data = await getBots(0, customerId, selects, botType);

  let results = response_data.data;
  if (response_data.pagination.total > response_data.data.length) {
    const offset_increment = response_data.pagination.limit ?? response_data.data.length;
    let offset = offset_increment;
    let promises = [];
    while (offset < response_data.pagination.total) {
      promises.push(getBots(offset, customerId, selects, botType));
      offset += offset_increment;
    }
    for (const promise of promises) {
      results.push(...(await promise).data);
    }
  }

  return results;
};
async function getAssignments(offset: number, customerId: string): Promise<any> {
  const queryParams = new URLSearchParams();
  queryParams.append("owner_id", customerId);

  const response: any = await API.get(`/assignments?${queryParams.toString()}&offset=${offset}`);

  return response.data;
}

// hay que arreglar el endpoint, actualmente no filtra por customer
export const getAllAssignmentsByCustomer = async (customerId: string): Promise<any[]> => {
  let response_data = await getAssignments(0, customerId);

  let results = response_data.data;
  if (response_data.pagination.total > response_data.data.length) {
    const offset_increment = response_data.pagination.limit ?? response_data.data.length;
    let offset = offset_increment;
    let promises = [];
    while (offset < response_data.pagination.total) {
      promises.push(getAssignments(offset, customerId));
      offset += offset_increment;
    }
    for (const promise of promises) {
      results.push(...(await promise).data);
    }
  }
  return results;
};

//VENUES
export const getVenues = async (venueId?: string): Promise<any[]> => {
  try {
    const endpoint = venueId ? `/venues/${venueId}` : `/venues`;

    const response = await API.get(endpoint);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching venues:", error);
    throw error;
  }
};

//CUSTOMERS
export const createCustomer = async (customerName: string, userEmail?: any): Promise<any> => {
  try {
    const id = uuidv4();
    const body = {
      id: id,
      name: customerName,
      sentinel_mails: userEmail,
    };
    const response = await API.post("/customers", body);
    return response.data || [];
  } catch (error) {
    console.error("Error fetching venues:", error);
    throw error;
  }
};
export const getCustomer = async (customerId: string): Promise<any[]> => {
  try {
    const response = await API.get(`/customers/${customerId}`);
    return response.data || [];
  } catch (error) {
    console.error("Error fetching venues:", error);
    throw error;
  }
};
export const putCustomer = async (customerId: string, body: any): Promise<any[]> => {
  try {
    const response = await API.put(`/customers/${customerId}`, body);
    return response.data.data || [];
  } catch (error) {
    console.error("Error fetching venues:", error);
    throw error;
  }
};

// USERS
export const getUser = async (userId: string): Promise<any> => {
  try {
    const response = await API.get(`/users/${userId}`);
    return response.data || [];
  } catch (error) {
    console.error("Error changing user:", error);
    throw error;
  }
};
export const putUser = async (body: User): Promise<any> => {
  try {
    const response = await API.put(`/users/${body.id}`, body);
    return response.data || [];
  } catch (error) {
    console.error("Error changing user:", error);
    throw error;
  }
};

export default {
  createAssignment,
  deleteAssigment,
  getAssigmentByNumber,
  updateAssignment,
  getAssignmentsIds,
  getIntegrations,
  getIntegrationById,
  getAllBotsByCustomer,
  getVenues,
  createCustomer,
  getCustomer,
  putCustomer,
  getUser,
  putUser,
  getAllAssignmentsByCustomer,
};
