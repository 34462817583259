/* eslint-disable */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BotsState {
  bots: any[];
  loading: boolean;
  error: string | null;
}

const initialState: BotsState = {
  bots: [],
  loading: false,
  error: null,
};

const botsSlice = createSlice({
  name: "bots",
  initialState,
  reducers: {
    loadBotsStart(state) {
      state.loading = true;
      state.error = null;
    },
    loadBotsSuccess(state, action: PayloadAction<any[]>) {
      state.bots = action.payload;
      state.loading = false;
    },
    loadBotsFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loading = false;
    },
    deleteBot(state, action: PayloadAction<string>) {
      // Eliminar el bot utilizando solo el `botId`
      state.bots = state.bots.filter(bot => bot.id !== action.payload);
    }
  },
});

export const { loadBotsStart, loadBotsSuccess, loadBotsFailure, deleteBot } = botsSlice.actions;

export default botsSlice.reducer;
